import {
  ArcRotateCamera,
  Vector3,
  HemisphericLight,
  SceneLoader,
  Color3,
  Scene,
  ISceneLoaderProgressEvent,
  Sprite,
  SpriteManager,
  ActionManager,
  ExecuteCodeAction,
  Color4,
  CubeTexture
} from "@babylonjs/core";
import { createRef, useContext } from "react";
import Loader from "../Loader/Loader";
import SceneComponent from "./SceneComponent";
import { isMobile } from "../../utils/utils";
import { AppContainerContext } from "../../context/AppContext";
import { HotspotModel } from "../../interface/hotspots.model";
import DataContainer from "../../services/DataContainer";
import { Icons } from "../../constants/Icons/Icons";

const Babylon = () => {
  const { hotspots, isProductLoading } = useContext(AppContainerContext);
  const loaderRef: React.RefObject<HTMLInputElement | HTMLDivElement> = createRef();
  let light: HemisphericLight;

  const onSceneReady = (scene: Scene) => {
    const { sections }: any = DataContainer.mApplicationData;
    if (!isProductLoading) {
      scene.clearColor = new Color4(0, 0, 0, 0);
      const camera: ArcRotateCamera = new ArcRotateCamera(
        "camera",
        1.8,
        0.8,
        isMobile() ? 65 : 100,
        new Vector3(0, -5, 0),
        scene
      );
      const canvas = scene.getEngine().getRenderingCanvas();
      camera.attachControl(canvas, true);
      camera.upperRadiusLimit = isMobile() ? 65 : 100;
      camera.lowerRadiusLimit = 25;
      camera.upperBetaLimit = 1;
      camera.useAutoRotationBehavior = true;
      const spriteManager = new SpriteManager(
        "spriteManager",
        "http://virtual-showroom-9000.onecx.stag.rapidimages.net.s3-website.eu-central-1.amazonaws.com/blue-dot.webp",
        hotspots.length,
        1500,
        scene
      );
      spriteManager.isPickable = true;

      const previewDiv = document.createElement("a");
      previewDiv.style.width = "0px";
      previewDiv.style.height = "0px";
      const bubbleCircle = document.createElement("div");

      const previewText = document.createElement("span");

      const setPreviewData = (preview: { title: string; backgroundImage: string }) => {
        previewText.innerText = `Explore the ${preview.title}`;
        previewDiv.style.backgroundImage = `url(${preview.backgroundImage})`;
      };

      const showPreview = (id: number, hotspotName: string, backgroundImageURL: string) => {
        setPreviewData({
          title: DataContainer.getSingelSectionValue(sections, "Hotspots Name", hotspotName),
          backgroundImage: backgroundImageURL
        });
        previewDiv.style.display = "flex";
        previewDiv.style.width = "230px";
        previewDiv.style.height = "230px";
        previewDiv.className =
          "absolute justify-center flex-col items-center rounded-full bg-black text-white z-50 bg-cover animate-scaling origin-bottom";
        previewText.style.display = "block";
        previewText.className = "text-sm font-medium animate-scaling text-center m-auto absolute";
        previewDiv.appendChild(previewText);
        document.getElementById("root")?.appendChild(previewDiv);
        previewDiv.href = `/${hotspotName}`;
        previewDiv.target = "_self";
        bubbleCircle.style.width = "18px";
        bubbleCircle.style.height = "18px";
        bubbleCircle.className = "rounded-full border-2 border-white mt-auto mb-2 relative";
        bubbleCircle.style.backgroundImage = "url('./circle-dot.webp')";
        previewDiv.appendChild(bubbleCircle);
      };

      const hidePreview = () => {
        window.removeEventListener("mousemove", () => {
          return;
        });
        window.removeEventListener("touchstart", () => {
          return;
        });
        previewDiv.style.display = "none";
        previewText.style.display = "none";
      };

      const createHotspotInstance = (
        position: Vector3,
        id: number,
        hotspotName: string,
        backgroundImageURL: string
      ) => {
        const hotspotInstance = new Sprite("hotspot", spriteManager);
        hotspotInstance.position = position;
        hotspotInstance.size = 1;
        hotspotInstance.isPickable = true;
        hotspotInstance.actionManager = new ActionManager(scene);
        hotspotInstance.actionManager?.registerAction(
          new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, function () {
            window.addEventListener(
              "mousemove",
              (e: MouseEvent) => {
                const target = e.target as HTMLCanvasElement;

                const rect = target?.getBoundingClientRect();

                const x = e.clientX - rect.left;
                const y = e.clientY - rect.top;

                previewDiv.style.top = y - 223 + "px";
                previewDiv.style.left = x - 115 + "px";
              },
              { once: true }
            );
            window.addEventListener(
              "touchstart",
              (e: TouchEvent) => {
                previewDiv.style.top = e.changedTouches[0].pageY - 223 + "px";
                previewDiv.style.left = e.changedTouches[0].pageX - 115 + "px";
              },
              { once: true }
            );
            showPreview(id, hotspotName, backgroundImageURL);
          })
        );
        hotspotInstance.actionManager?.registerAction(
          new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, function () {
            hidePreview();
          })
        );
        return hotspotInstance;
      };

      hotspots.forEach((hotspot: HotspotModel, index: number) => {
        createHotspotInstance(
          new Vector3(hotspot.position.x, hotspot.position.y, hotspot.position.z),
          index,
          hotspot.name,
          hotspot.asset_url
        );
      });

      const autoRotationBehaviorElement: HTMLElement = document.querySelector(".orbit")!;
      autoRotationBehaviorElement.innerHTML = `${Icons?.Play}`;
      autoRotationBehaviorElement.onclick = function () {
        camera.useAutoRotationBehavior = !camera.useAutoRotationBehavior;
        if (!camera.autoRotationBehavior) {
          autoRotationBehaviorElement.innerHTML = `${Icons?.Pause}`;
        } else {
          autoRotationBehaviorElement.innerHTML = `${Icons?.Play}`;
        }
      };

      SceneLoader.ShowLoadingScreen = false;
      light = new HemisphericLight("light", new Vector3(0, 1, 0), scene);
      SceneLoader.Append(
        "http://virtual-showroom-9000.onecx.stag.rapidimages.net.s3-website.eu-central-1.amazonaws.com/",
        "scene_no_hotspots.babylon",
        scene,
        (scene: Scene) => {
          scene.activeCamera = camera;
          scene.clearColor = Color3.FromHexString("#B5B5B5").toColor4();
          const hdrTexture = CubeTexture.CreateFromPrefilteredData(
            "http://virtual-showroom-9000.onecx.stag.rapidimages.net.s3-website.eu-central-1.amazonaws.com/studio.env",
            scene
          );
          const skybox = scene.createDefaultSkybox(hdrTexture, true, 1);
        },
        (ev: ISceneLoaderProgressEvent) => {
          if (loaderRef.current && ev.loaded > 0) {
            const loadingElement = Array.from(loaderRef.current.querySelectorAll(".loader"));
            const setLoadingText = loaderRef.current.querySelector(".text-loading") as HTMLElement;
            const progress = `${((ev.loaded * 100) / ev.total).toFixed()}%`;
            loadingElement.map((element: any) => {
              element.style.width = progress;
              if (setLoadingText) {
                setLoadingText.innerText = `Loading 3D Scene (${progress})`;
                if (element.style.width === "100%") setLoadingText.innerText = "Preparing Scene";
              }
            });
          }
          scene.whenReadyAsync().then(() => {
            if (!isProductLoading) {
              loaderRef.current && loaderRef.current.remove();
            }
          });
        }
      );
    }
  };

  return (
    <>
      <Loader ref={loaderRef} />
      <div className="w-full h-full">
        <SceneComponent
          antialias
          onSceneReady={onSceneReady}
          id="my-scene"
          className="w-full h-screen"
        />
      </div>
      <div className="flex items-center absolute w-max bottom-5 left-4 cursor-pointer">
        <span className="orbit"></span>
        <p className="pl-3 text-base font-normal">Auto Orbit</p>
      </div>
    </>
  );
};

export default Babylon;
