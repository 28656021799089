import Babylon from "../../components/WebGL";

const Home = () => {
  return (
    <div className="w-full h-full">
      <Babylon />
    </div>
  );
};

export default Home;
