import { Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import AppContextProvider from "./context/AppContext";
import HeaderContextProvider from "./context/HeaderContext";
import Home from "./templates/Home/Home";
import PanoramaView from "./templates/Home/PanoramaView";

function App() {
  return (
    <>
      <HeaderContextProvider>
        <Header />
      </HeaderContextProvider>
      <AppContextProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:hotspotName" element={<PanoramaView />} />
        </Routes>
      </AppContextProvider>
    </>
  );
}

export default App;
