import React, { createContext, useEffect, useState } from "react";
import { ComponentEnum } from "../enum/componet.model";
import { AvailableVariations, ComponentModel, ProductModel } from "../interface/component.model";
import { HotspotModel } from "../interface/hotspots.model";
import dataContainer from "../services/DataContainer";

export const AppContainerContext = createContext<any>(null);

const AppContextProvider = ({ children }: React.PropsWithChildren) => {
  const [product, setProduct] = useState<ProductModel>();
  const [hotspots, setHotspost] = useState<HotspotModel[]>([]);
  const [isProductLoading, setIsProductLoading] = useState(true);

  useEffect(() => {
    dataContainer.loadData(() => {
      dataContainer.instance().mLoadedData;
      setProduct(dataContainer.mLoadedData!);
      setIsProductLoading(false);
    });
  }, []);

  useEffect(() => {
    !isProductLoading &&
      product?.component_groups[0].components.forEach((item: ComponentModel) => {
        if (item.name === ComponentEnum.HOTSPOTS) {
          item.available_variations.forEach((i: AvailableVariations) => {
            setHotspost((hotspot) => [
              ...hotspot,
              {
                asset_url: i?.assets[0]?.asset_url,
                name: i.key,
                position: {
                  x: parseFloat(i?.assets[0]?.attribute_values?.[0].value),
                  y: parseFloat(i?.assets[0]?.attribute_values?.[1].value),
                  z: parseFloat(i?.assets[0]?.attribute_values?.[2].value)
                }
              }
            ]);
          });
        }
      });
  }, [!isProductLoading]);

  return (
    <AppContainerContext.Provider value={{ hotspots, isProductLoading, product }}>
      {children}
    </AppContainerContext.Provider>
  );
};

export default AppContextProvider;
