import { forwardRef } from "react";
import { LoaderProps } from "./types";

// eslint-disable-next-line react/display-name
const Loader = forwardRef(({ text }: LoaderProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  return (
    <div
      className="w-full h-full absolute z-50 top-0 flex flex-col items-center justify-center bg-white"
      ref={ref}
    >
      <div className="progressbar absolute h-14 w-72 light-purple">
        <div className="w-full h-full absolute front shadow-md">
          <div className="w-0 h-full loader bg-gray-light shadow-light-gray transition-all duration-100 ease-in"></div>
        </div>
        <div className="w-full h-full absolute back shadow-md">
          <div className="w-0 h-full loader bg-gray-light shadow-light-gray transition-all duration-100 ease-in"></div>
        </div>
        <div className="w-full h-full absolute top origin-bottom shadow-md">
          <div className="w-0 h-full loader bg-gray-light shadow-light-gray transition-all duration-100 ease-in"></div>
        </div>
        <div className="w-full h-full absolute origin-bottom bottom shadow-md">
          <div className="w-0 h-full loader bg-gray-light shadow-light-gray transition-all duration-100 ease-in"></div>
        </div>
        <div className="w-0 h-full absolute bg-gray-light shadow-light-gray origin-left left"></div>
      </div>
      <p className="text-loading absolute z-50 mt-24 font-light">Loading 3D Scene (0%)</p>
    </div>
  );
});

export default Loader;
