import classNames from "classnames";
import { HeadlineProps } from "./HeadlineProps";

export const Typography = ({ children, tag, className, ...rest }: HeadlineProps) => {
  const Tag = tag;
  const baseClass = "vsh-headline";
  return (
    <Tag className={classNames(baseClass, className)} {...rest}>
      {children}
    </Tag>
  );
};
