import { useContext } from "react";
import { useParams } from "react-router-dom";
import S360View from "../../components/360View/360View";
import { AppContainerContext } from "../../context/AppContext";
import { ComponentModel } from "../../interface/component.model";

const PanoramaView = () => {
  const { hotspotName } = useParams();
  const { product } = useContext(AppContainerContext);

  if (!product) {
    return (
      <h3 className="absolute top-1/2 left-0 right-0 ml-auto mr-auto w-max">
        Loading Panorama View
      </h3>
    );
  }

  return product?.component_groups[0].components.map(({ name , asset}: ComponentModel) => {
    if (name === hotspotName) {
      return <S360View key={hotspotName} image_url={asset} />;
    }
  });
};

export default PanoramaView;
