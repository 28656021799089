import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { Icons } from "../../constants/Icons/Icons";
import { useNavigate } from "react-router-dom";
import { Typography } from "../Typography/Typography";
import { HeaderContainerContext } from "../../context/HeaderContext";

const Navbar = () => {
  const { isSidebarOpen, setIsSidebarOpen } = useContext(HeaderContainerContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  return (
    <div className="absolute z-40 top-0 py-12 h-14 bg-transparent text-white font-bold flex justify-end items-center w-full px-10">
      {pathname === "/" ? (
        <button
          onClick={() => {
            setIsSidebarOpen(!isSidebarOpen);
          }}>
          {Icons.Menu}
        </button>
      ) : (
        <div className="w-full flex md:justify-between items-center">
          <button
            className="text-white font-bold justify-start items-center flex"
            onClick={() => navigate("/")}>
            <Typography tag="p" className="text-sm hidden md:block font-normal text-white px-4">
              Back to showroom
            </Typography>
            <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
              {isHovered ? Icons.BackPanoIconOpen : Icons.BackPanoIcon}
            </div>
          </button>
        </div>
      )}
    </div>
  );
};

export default Navbar;
