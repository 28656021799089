import { useContext, useEffect, useRef, useState } from "react";
import { S360ViewProps } from "./360ViewProps";
import ViewDescription from "./ViewDescription/ViewDescription";
import DataContainer, { $isProductLoaded } from "../../services/DataContainer";
import { useParams } from "react-router-dom";
import {
  HotspotPositionModel,
  Pannellum360,
  PanormaViewModel
} from "../../interface/panorma.model";
import { Pannellum } from "pannellum-react";
import { Icons } from "../../constants/Icons/Icons";
import { AppContainerContext } from "../../context/AppContext";
import { Asset, AvailableVariations, ComponentModel } from "../../interface/component.model";
import { isMobile } from "../../utils/utils";

const S360View = ({ image_url }: S360ViewProps) => {
  const { product } = useContext(AppContainerContext);
  const [canImageRotate, setCanImageRotate] = useState(true);
  const { sections }: any = DataContainer.mApplicationData;
  const [areSectionsLoaded, setSectionsLoaded] = useState(false);
  const [markets, setMarkets] = useState<PanormaViewModel>();
  const { hotspotName } = useParams();
  const orbit = useRef<Pannellum360>(null);
  const [hotspotPositions, setHotspostPositions] = useState<HotspotPositionModel[]>([]);

  useEffect(() => {
    $isProductLoaded.subscribe((isReady: boolean) => setSectionsLoaded(isReady));
  }, []);

  useEffect(() => {
    product?.component_groups[0].components?.map((component: ComponentModel) => {
      if (component.name === hotspotName) {
        const Variants: AvailableVariations[] = getVariantOfSelectedComponent(component.id);
        Variants.map((variant: AvailableVariations) => {
          variant.assets.map((asset: Asset) => {
            const selectedVariant: AvailableVariations = filterVariants(asset.variation_id);
            positionTheHotspots({
              key: selectedVariant.key,
              x: asset.attribute_values[0].value,
              y: asset.attribute_values[1].value
            });
          });
        });
      }
    });
  }, [product]);

  const positionTheHotspots = (hotspotPosition: HotspotPositionModel) => {
    setHotspostPositions((prev: HotspotPositionModel[]) => [
      ...prev,
      {
        key: hotspotPosition.key,
        x: hotspotPosition.x,
        y: hotspotPosition.y
      }
    ]);
  };

  const getVariantOfSelectedComponent = (componentID: number) => {
    return DataContainer.findComponentVariations(componentID);
  };

  const filterVariants = (variantID: number) => {
    return DataContainer.getAllVariations().filter(
      (variant: AvailableVariations) => variant.id === variantID
    )[0];
  };

  useEffect(() => {
    if (areSectionsLoaded) {
      sections.map(
        (item: {
          section_name: string;
          key_values: { title: string; description: string; link: string };
        }) => {
          if (item.section_name === hotspotName) {
            setMarkets({
              title: item.key_values["title"],
              description: item.key_values["description"],
              link: item.key_values["link"]
            });
          }
        }
      );
    }
  }, [areSectionsLoaded]);

  const hotspotContent = (sectionName: string, keyValue: string) => {
    let value = "";
    sections?.map(({section_name, key_values}: { section_name: string; key_values: any }) => {
      if (section_name === sectionName) {
        value = key_values[keyValue];
      }
    });
    return value;
  };

  const toggleOrbit = () => {
    setCanImageRotate((prev: boolean) => !prev);
    if (canImageRotate) {
      orbit.current && orbit.current.getViewer().stopAutoRotate();
    } else {
      orbit.current && orbit.current.getViewer().startAutoRotate(-2);
    }
  };

  const contentOfHotspot = (key: string) => {
    return `<div class="flex items-start rounded-lg justify-center flex-col cursor-pointer bg-white pl-11 font-medium w-[500px] h-[140px]">
            <h1 className="font-bold text-black text-xl pb-5">${hotspotContent(key, "hotspot_title")}</h1>
            <p className="font-normal text-xl text-black">${hotspotContent(key, "hotspot_description")}</p>
            </div>`;
  };

  return (
    <div className="max-h-screen h-screen">
      <div className="w-full h-full">
        <Pannellum
          ref={orbit}
          id="panorama"
          width="100%"
          height="100%"
          image={image_url}
          autoLoad
          maxHfov={!isMobile() ? 60 : 120}
          minHfov={!isMobile() ? 20 : 40}
          maxPitch={40}
          autoRotate={-2}>
          {hotspotPositions &&
            hotspotPositions.map((hotspot: HotspotPositionModel, index: number) => (
              <Pannellum.Hotspot
                key={index + 1}
                type="info"
                pitch={hotspot.y}
                yaw={hotspot.x}
                text={contentOfHotspot(hotspot.key)}
              />
            ))}
        </Pannellum>
        <div className="absolute z-50 left-3 cursor-pointer bottom-[17vh]" onClick={toggleOrbit}>
          {canImageRotate ? Icons.PanoramaPlay : Icons.PanoramaPause}
        </div>
      </div>
      <div className="relative flex flex-col">
        <ViewDescription
          headline={markets?.title}
          content={markets?.description}
          footer={markets?.link}
          imageDescription={{ top: "Next", bottom: "Virtual Reality booths" }}
        />
      </div>
    </div>
  );
};

export default S360View;
