import classnames from "classnames";
import { useState } from "react";
import { Icons } from "../../../constants/Icons/Icons";
import { isMobile } from "../../../utils/utils";
import { Typography } from "../../Typography/Typography";
import { ViewDescriptionProps } from "./ViewDescriptionProps";

const ViewDescription = ({ headline, content, footer, imageDescription }: ViewDescriptionProps) => {
  const [showDescription, setShowDescription] = useState<boolean>(false);

  return (
    <div
      className={classnames(
        "bg-white absolute bottom-0 z-50 w-full flex flex-col md:flex-row items-center transition ease-in-out duration-300",
        { "md:min-h-[28vh] h-screen max-h-screen md:h-[28vh]": showDescription },
        { "md:min-h-[6vh]": !showDescription }
      )}>
      <div className="flex flex-row-reverse md:flex-row md:w-4/6">
        <div className="w-[10vw]">
          <div
            className={classnames(
              "flex justify-center py-10 pr-4 md:pt-8 w-full cursor-pointer",
              {
                "xl:pt-14 lg:pt-8 items-start md:items-center lg:items-start": showDescription
              },
              { "md:min-h-[6vh] items-center": !showDescription }
            )}
            onClick={() => {
              setShowDescription(!showDescription);
            }}>
            {showDescription ? Icons.DownArrow : Icons.UpArrow}
          </div>
        </div>
        <div
          className={classnames(
            "w-[90vw] flex flex-col pl-8 items-center justify-center md:items-center md:pt-0 max:h-[15vh]",
            { "pt-8 md:min-h-[28vh]": showDescription },
            { "md:min-h-[6vh]": !showDescription }
          )}>
          <div className="h-auto w-full flex flex-col justify-between items-start px-2 py-0 md:px-0 xl:pt-6 xl:pb-6 ">
            {headline && (
              <Typography tag="h3" className="pb-0 text-xl font-medium">
                {headline}
              </Typography>
            )}
            {showDescription && (
              <>
                {content && (
                  <Typography className="block font-light text-lg pt-4 md:pt-4 pb-5" tag="p">
                    {content}
                  </Typography>
                )}
                {footer && (
                  <button className="pt-0">
                    <Typography
                      tag="h3"
                      className="text-blue-light text-lg block text-left md:text-center font-medium">
                      {footer}
                    </Typography>
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <div
        className={classnames(
          "md:w-2/6 w-full h-full md:image-description mb-4 items-start justify-end px-10 py-0 md:px-0 xl:pt-6 xl:pb-6",
          { "flex flex-col": showDescription && isMobile },
          { hidden: !showDescription && isMobile }
        )}>
        <div className="h-px w-full md:hidden bg-black mb-4"></div>
        <button className="md:text-white">{imageDescription.top}</button>
        <strong className="md:text-white text-xl">{imageDescription.bottom}</strong>
      </div>
    </div>
  );
};

export default ViewDescription;
