import { useContext } from "react";
import { HeaderContainerContext } from "../../context/HeaderContext";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";

const Header = () => {
  const { sidebarItems } = useContext(HeaderContainerContext);
  return (
    <>
      <Navbar />
      <Sidebar sidebarList={sidebarItems} />
    </>
  );
};

export default Header;
