import classnames from "classnames";
import { useContext, useEffect, useRef, useState } from "react";
import { SideBarProps } from "./SidebarProps";
import { useId } from "react";
import { Icons } from "../../constants/Icons/Icons";
import DataContainer from "../../services/DataContainer";
import { HeaderContainerContext } from "../../context/HeaderContext";
import { Link } from "react-router-dom";

const Sidebar = ({ sidebarList }: SideBarProps) => {
  const [sidebarItems, setSidebarItems] = useState<string[]>([]);
  const { isSidebarOpen, setIsSidebarOpen } = useContext(HeaderContainerContext);
  const sidebarRef = useRef(null);
  const itemId = useId();
  const { sections }: any = DataContainer.mApplicationData;

  const handleOutsideClick = (ref?: React.RefObject<HTMLDivElement>, event?: Event) => {
    if (event?.target === ref?.current) {
      setIsSidebarOpen(false);
    }
  };

  const addZeroBeforeNumber = (num: number) => {
    if (num + 1 < 10) {
      return `0${num + 1}`;
    } else {
      return num + 1;
    }
  };

  const onClickSidebarElement = (key?: string) => {
    setIsSidebarOpen(false);
  };

  useEffect(() => {
    setSidebarItems(sidebarList);
    if (isSidebarOpen) {
      document.addEventListener("click", (e: Event) => {
        handleOutsideClick(sidebarRef, e);
      });
    } else {
      return document.removeEventListener("click", (e: Event) => {
        handleOutsideClick(sidebarRef, e);
      });
    }
  }, [isSidebarOpen]);

  return (
    <>
      {isSidebarOpen && (
        <div
          className={classnames(
            "w-screen h-screen absolute z-50 top-0 flex flex-col md:flex-row transition ease-in-out"
          )}>
          <div
            ref={sidebarRef}
            className="bg-transparent backdrop-blur-sm w-3/5 hidden md:block"></div>
          <div className="md:w-3/6 w-screen bg-black-light md:bg-gray-medium text-white relative">
            <ul className="h-screen overflow-x-hidden">
              <div className="w-full top-0 sticky bg-black-light flex md:hidden items-center justify-end pt-8 px-8 md:px-21 cursor-pointer">
                <button onClick={() => setIsSidebarOpen(false)}>
                  {window.innerWidth > 767 ? Icons.smallCloseIcon : Icons.bigCloseIcon}
                </button>
              </div>
              {sidebarItems.map((name: string, index: number) => (
                <Link key={`${itemId} ${index}`} to={`/${name}`} target="_self">
                  <li
                    id={itemId + `${index}`}
                    key={index}
                    className="px-8 md:px-21 cursor-pointer"
                    onClick={() => onClickSidebarElement(name)}>
                    <div
                      className={classnames(
                        "flex justify-between items-end border-b border-white-medium border-opacity-10",
                        { "py-9 md:py-16": index > 0 },
                        { "pb-9 md:pb-16 pt-3 md:pt-14": index === 0 }
                      )}>
                      <div className="flex flex-col text-white-medium font-light">
                        <span className="text-[8px] md:text-xs">{addZeroBeforeNumber(index)}</span>
                        <span className="pt-2.5 text-lg md:text-xl">
                          {DataContainer.getSingelSectionValue(sections, "Hotspots Name", name)}
                        </span>
                      </div>
                      <span className="block md:hidden">{Icons.RightArrow}</span>
                    </div>
                  </li>
                </Link>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
