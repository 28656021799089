export const Icons = {
  Menu: (
    <svg width="30" height="25" xmlns="http://www.w3.org/2000/svg">
      <g>
        <line
          transform="rotate(1 297.031 99.0307)"
          stroke="#000"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          id="svg_1"
          y2="98.0307"
          x2="340.67544"
          y1="100.0307"
          x1="253.38596"
          fill="none"
        />
        <line
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          id="svg_2"
          y2="91.0307"
          x2="341.00439"
          y1="92.0307"
          x1="235.00439"
          stroke="#000"
          fill="none"
        />
        <line
          stroke="#000"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          id="svg_3"
          y2="7.88494"
          x2="46.41286"
          y1="7.88494"
          x1="2.92724"
          fill="none"
        />
        <line
          stroke="#000"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          id="svg_5"
          y2="14.14552"
          x2="45.02284"
          y1="14.14552"
          x1="8.30542"
          fill="none"
        />
      </g>
    </svg>
  ),
  smallCloseIcon: (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="10px"
      height="10px"
      fill="white"
      viewBox="0 0 122.878 122.88"
      enableBackground="new 0 0 122.878 122.88"
      xmlSpace="preserve">
      <g>
        <path d="M1.426,8.313c-1.901-1.901-1.901-4.984,0-6.886c1.901-1.902,4.984-1.902,6.886,0l53.127,53.127l53.127-53.127 c1.901-1.902,4.984-1.902,6.887,0c1.901,1.901,1.901,4.985,0,6.886L68.324,61.439l53.128,53.128c1.901,1.901,1.901,4.984,0,6.886 c-1.902,1.902-4.985,1.902-6.887,0L61.438,68.326L8.312,121.453c-1.901,1.902-4.984,1.902-6.886,0 c-1.901-1.901-1.901-4.984,0-6.886l53.127-53.128L1.426,8.313L1.426,8.313z" />
      </g>
    </svg>
  ),
  bigCloseIcon: (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="14px"
      height="14px"
      fill="white"
      viewBox="0 0 122.878 122.88"
      enableBackground="new 0 0 122.878 122.88"
      xmlSpace="preserve">
      <g>
        <path d="M1.426,8.313c-1.901-1.901-1.901-4.984,0-6.886c1.901-1.902,4.984-1.902,6.886,0l53.127,53.127l53.127-53.127 c1.901-1.902,4.984-1.902,6.887,0c1.901,1.901,1.901,4.985,0,6.886L68.324,61.439l53.128,53.128c1.901,1.901,1.901,4.984,0,6.886 c-1.902,1.902-4.985,1.902-6.887,0L61.438,68.326L8.312,121.453c-1.901,1.902-4.984,1.902-6.886,0 c-1.901-1.901-1.901-4.984,0-6.886l53.127-53.128L1.426,8.313L1.426,8.313z" />
      </g>
    </svg>
  ),
  Left: (
    <svg
      width="28px"
      height="28px"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" fill="white" fillOpacity="0.01" />
      <path
        d="M31 36L19 24L31 12"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  RightArrow: (
    <svg fill="none" height="17" viewBox="0 0 25 17" width="20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 8.5H24M24 8.5L16.5538 1M24 8.5L16.5538 16"
        stroke="white"
        strokeLinejoin="bevel"></path>
    </svg>
  ),
  UpArrow: (
    <svg width="26" height="15" viewBox="0 0 26 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 14L13 2.00005L25 14" stroke="black" strokeWidth="2" />
    </svg>
  ),
  DownArrow: (
    <svg width="26" height="15" viewBox="0 0 26 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L13 12.9999L25 1" stroke="black" strokeWidth="2" />
    </svg>
  ),
  Play: `<svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16.5" cy="16.5" r="16" stroke="black" />
      <rect x="12" y="11" width="2" height="12" fill="black" />
      <rect x="19" y="11" width="2" height="12" fill="black" />
    </svg>`,
  BackPanoIcon: (
    <svg width="72" height="41" viewBox="0 0 72 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.62822 21.1655L35.221 39.3167C35.5154 39.4758 35.8699 39.4771 36.1655 39.3201L70.3374 21.1689C71.0452 20.7929 71.0452 19.7786 70.3374 19.4026L36.1655 1.25132C35.8699 1.09433 35.5154 1.09559 35.221 1.25468L1.62823 19.4059C0.928726 19.7839 0.928725 20.7875 1.62822 21.1655Z"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M17.3571 20.607V13.2142L35.9999 2.92847L54.9642 13.2142V20.607L35.9999 30.8928L17.3571 20.607Z"
        fill="white"
      />
    </svg>
  ),
  BackPanoIconOpen: (
    <svg width="78" height="47" viewBox="0 0 78 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.61431 25.3784L38.2013 44.7421C38.4971 44.9031 38.854 44.9044 39.1509 44.7455L75.3515 25.3818C76.056 25.005 76.056 23.995 75.3515 23.6182L39.1509 4.25447C38.854 4.09564 38.4971 4.09692 38.2013 4.25786L2.61431 23.6216C1.91825 24.0004 1.91825 24.9996 2.61431 25.3784Z"
        stroke="white"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8794 24.869L28.4398 16.5118L27.9667 15.6309L12.8022 23.7754L8.37812 21.4159L7.90753 22.2982L12.729 24.8697L12.8041 24.7288L12.8794 24.869Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.2704 23.9871L39.8775 10.4871L39.4081 11.37L63.8244 24.3508L54.0601 29.7738L54.0539 29.7771L38.7383 38.0028L8.38256 21.4183L7.90311 22.2959L38.4965 39.0102L38.7342 39.14L38.9728 39.0119L54.5333 30.6548L54.5333 30.6548L54.5394 30.6514L64.957 24.8657L64.8785 24.7242L65.2704 23.9871Z"
        fill="white"
      />
      <path
        d="M20.3571 24.75V10.2857L39 0L57.9643 10.2857V24.75L39 35.0357L20.3571 24.75Z"
        fill="white"
      />
      <path
        d="M20.3571 24.75V10.2857L39 0L57.9643 10.2857L39 20.5714V35.0357L20.3571 24.75Z"
        fill="white"
      />
      <path
        d="M20.3571 24.75V10.2857L39 0L57.9643 10.2857L39 20.5714V35.0357L20.3571 24.75Z"
        fill="url(#paint0_linear_855_1301)"
      />
      <path d="M39 20.5714L20.3571 10.2857L39 0L57.9643 10.2857L39 20.5714Z" fill="white" />
      <defs>
        <linearGradient
          id="paint0_linear_855_1301"
          x1="39"
          y1="19.9286"
          x2="5.25001"
          y2="-2.25"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#69C0B5" stopOpacity="0.5" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
      </defs>
      \
    </svg>
  ),
  Pause: `<svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="16.5" cy="16.5" r="16" stroke="black"/>
  <path d="M21 17L13.5 21.3301L13.5 12.6699L21 17Z" fill="black"/>
  </svg>`,
  PanoramaPause: (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16.5" cy="16.5" r="16" stroke="white" />
      <path d="M21 17L13.5 21.3301L13.5 12.6699L21 17Z" fill="white" />
    </svg>
  ),
  PanoramaPlay: (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16.5" cy="16.5" r="16" stroke="white" />
      <rect x="12" y="11" width="2" height="12" fill="white" />
      <rect x="19" y="11" width="2" height="12" fill="white" />
    </svg>
  )
};
