import React, { createContext, useEffect, useState } from "react";
import { ComponentEnum } from "../enum/componet.model";
import { AvailableVariations, ComponentModel } from "../interface/component.model";
import DataContainer, { $isProductLoaded } from "../services/DataContainer";

export const HeaderContainerContext = createContext<any>(null);

const HeaderContextProvider = ({ children }: React.PropsWithChildren) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [sidebarItems, setSidebarItems] = useState<string[]>([]);
  const [isProductLoading, setIsProductLoading] = useState(false);

  useEffect(() => {
    $isProductLoaded.subscribe((isReady: boolean) => setIsProductLoading(isReady));
  }, []);

  useEffect(() => {
    if (isProductLoading) {
      DataContainer.mComponents.forEach((component: ComponentModel) => {
        if (component.name === ComponentEnum.HOTSPOTS) {
          DataContainer.mVariations.forEach((variant: AvailableVariations) => {
            if (variant.component_id === component.id) {
              setSidebarItems((prev: string[]) => [...prev, variant.key]);
            }
          });
        }
      });
    }
  }, [isProductLoading]);

  return (
    <HeaderContainerContext.Provider value={{ sidebarItems, isSidebarOpen, setIsSidebarOpen }}>
      {children}
    </HeaderContainerContext.Provider>
  );
};

export default HeaderContextProvider;
